import { flatten } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import { logActivity } from '../services/company';
import { getActiveSubscription } from '../services/subscription';

const state = () => ({
  activeCompany: null,
  companies: [],
  activeCompanySkills: [],
  members: [],
  customers: [],
  competitors: [],
  hasFetchedCompetitors: false,
  tamValue: null,
  samValue: null,
  somValue: null,
  swotFactors: [],
  pestleFactors: [],
  isFetchingCompetitors: false,
  completionData: {},
  totalModules: 28,
  isShareView: false,
  selectedCompetitors: [],
  selectedMarketCustomer: {},
  notifications: [],
});

const mutations = {
  setActiveCompany(state, company) {
    if (!company) {
      state.activeCompany = null;
      return;
    }
    state.activeCompany = company;

    // set active billing plan
    if (company.billing_subscriptions?.length > 0) {
      const activeSubscription = company?.billing_subscriptions.find(
        (sub) => sub.status === 'active'
      );
      if (activeSubscription) {
        state.activeCompany.activeSubscription = activeSubscription;
      } else {
        state.activeCompany.activeSubscription =
          company.billing_subscriptions[0];
      }
    } else {
      state.activeCompany = {
        ...state.activeCompany,
        activeSubscription: {
          priceId: 'free',
          status: 'active',
        },
      };
    }
  },
  setTargetMargetCustomer(state, customer) {
    state.selectedMarketCustomer = customer;
  },
  updateActiveCompany(state, company) {
    state.activeCompany = { ...state.activeCompany, ...company };
  },
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setMembers(state, members) {
    state.members = members;
  },
  updateMember(state, { index, member }) {
    state.members.splice(index, 1, member);
  },
  setActiveCompanySkills(state, skills) {
    state.activeCompanySkills = skills;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  addCustomer(state, customer) {
    state.customers.push(cloneDeep(customer));
  },
  updateCustomer(state, { index, customer, deleteCount = 1 }) {
    state.customers.splice(index, deleteCount, customer);
  },
  deleteCustomer(state, index) {
    state.customers.splice(index, 1);
  },
  setCompetitors(state, competitors) {
    state.competitors = competitors;
  },
  setHasFetchedCompetitors(state, value) {
    state.hasFetchedCompetitors = value;
  },
  setIsFetchingCompetitors(state, value) {
    state.isFetchingCompetitors = value;
  },
  updateCompetitor(state, { index, competitor, deleteCount = 1 }) {
    state.competitors.splice(index, deleteCount, cloneDeep(competitor));
  },
  addCompetitor(state, competitor) {
    state.competitors.push(cloneDeep(competitor));
  },
  deleteCompetitor(state, index) {
    state.competitors.splice(index, 1);
  },
  setTam(state, value) {
    state.tamValue = value;
  },
  setSam(state, value) {
    state.samValue = value;
  },
  setSom(state, value) {
    state.somValue = value;
  },
  setSwotFactors(state, factors) {
    state.swotFactors = factors;
  },
  setPestleFactors(state, factors) {
    state.pestleFactors = factors;
  },
  setCompletionData(state, completionData) {
    state.completionData = completionData;
  },
  setShareView(state, shareState) {
    state.isShareView = shareState;
  },
  setSelectedCompetitors(state, ids) {
    state.selectedCompetitors = ids;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  async setActiveCompany({ commit }, company) {
    const companyId = company.id;
    try {
      const res = await getActiveSubscription(companyId);

      const subscription = res.data.billing_subscription;

      //  add subscription as a property to the company object
      commit('setActiveCompany', {
        ...company,
        billing_subscriptions: subscription,
      });
    } catch (error) {
      commit('setActiveCompany', company);
    }
  },
  setTargetMargetCustomer({ commit }, customer) {
    commit('setTargetMargetCustomer', customer);
  },
  setCompanies({ commit }, companies) {
    commit('setCompanies', companies);
  },
  setActiveCompanySkills({ commit }, skills) {
    commit('setActiveCompanySkills', skills);
  },
  setCustomers({ commit }, customers) {
    commit('setCustomers', customers);
  },
  addCustomer({ commit }, customer) {
    commit('addCustomer', customer);
  },
  updateCustomer({ commit, state }, customer) {
    const index = state.customers.findIndex(
      (_customer) => _customer.id === customer.id
    );
    commit('updateCustomer', { index, customer: cloneDeep(customer) });
  },
  updateMember({ commit, state }, member) {
    const index = state.members.findIndex(
      (_member) => _member.id === member.id
    );
    commit('updateMember', { index, member: cloneDeep(member) });
  },
  updateCompetitor({ commit, state }, competitor) {
    const index = state.competitors.findIndex(
      (_competitor) => _competitor.id === competitor.id
    );
    commit('updateCompetitor', { index, competitor: cloneDeep(competitor) });
  },
  async logAction({ state, rootState }, data) {
    let logData = null;
    if (!data.data[data.activityLogOperation.dataKey]) {
      return;
    }
    if (data.data[data.activityLogOperation.dataKey].returning) {
      logData = data.data[data.activityLogOperation.dataKey].returning[0];
    } else {
      logData = data.data[data.activityLogOperation.dataKey];
    }
    if (!logData.type) {
      logData.type = logData.__typename;
      logData.__typename = 'foundation';
    }
    const log = {
      companyId: state.activeCompany.id,
      component: logData.type || 'foundation',
      data: data.operation.variables,
      type: logData.__typename,
      userId: rootState.auth.user.id,
    };
    try {
      await logActivity(log);
    } catch (e) {
      console.log(e);
    }
  },
  resetCompanyState({ commit }, company) {
    commit('setActiveCompany', company);
    commit('setCompanies', []);
    commit('setActiveCompanySkills', []);
    commit('setMembers', []);
    commit('setCustomers', []);
    commit('setCompetitors', []);
    commit('setTam', null);
    commit('setSam', null);
    commit('setSom', null);
    commit('setSwotFactors', []);
    commit('setPestleFactors', []);
    commit('setSelectedCompetitors', []);
    commit('setNotifications', []);
  },
};

const getters = {
  getActiveCompany: (state) => state.activeCompany,
  getSectorIds: (state) => {
    return state.activeCompany.company_sectors.map((sector) => sector.sectorId);
  },
  isCompanyOwner: (state, getters, rootState) => {
    return state.activeCompany.createdBy === rootState.auth.user.id;
  },
  isCompanyAdmin: (state, getters, rootState) => {
    if (state.isShareView) return false;
    const teamData = rootState.auth.user.teams.find(
      (team) => team.companyId === state.activeCompany.id
    );
    return teamData?.role === 'ADMIN';
  },
  growthModules: (state) => {
    const moduleKeys = Object.keys(state.completionData);
    const modules = flatten(moduleKeys.map((key) => state.completionData[key]));
    return modules;
  },
  companyLevelPercentage: (state, getters) => {
    const completedModules = getters.growthModules.filter(
      (component) => component.progress === 'DONE'
    );
    return completedModules.length
      ? Math.round((completedModules.length / state.totalModules) * 100)
      : 0;
  },
  companyLevel: (state, getters) => {
    if (getters.companyLevelPercentage < 20) {
      return 'Not robust';
    } else if (
      getters.companyLevelPercentage > 20 &&
      getters.companyLevelPercentage < 40
    ) {
      return 'Somewhat robust';
    } else if (
      getters.companyLevelPercentage > 40 &&
      getters.companyLevelPercentage < 60
    ) {
      return 'Robust Enough';
    } else if (
      getters.companyLevelPercentage > 60 &&
      getters.companyLevelPercentage < 80
    ) {
      return 'Very Robust';
    } else if (
      getters.companyLevelPercentage > 80 &&
      getters.companyLevelPercentage <= 100
    ) {
      return 'Solid';
    }
  },
  getModuleProgress: (state, getters) => (type) => {
    const growthModule = getters.growthModules.find(
      (module) => module.type == type || module.__typename == type
    );
    return growthModule ? growthModule.progress : 'NOT_DONE';
  },
  calculateTotal: () => (obj) => {
    const doneModules = Object.keys(obj).filter((key) => obj[key] === 'DONE');
    return doneModules.length
      ? Math.round((doneModules.length / Object.keys(obj).length) * 100)
      : 0;
  },
  ideaStageLevel: (state, getters) => {
    const companySkill = getters.growthModules.find(
      (module) => module.__typename === 'company_skill'
    );
    const obj = {
      overall_solution: getters.getModuleProgress('overall_solution'),
      overall_problem: getters.getModuleProgress('overall_problem'),
      overall_value_proposition: getters.getModuleProgress(
        'overall_value_proposition'
      ),
      customer_segment: getters.getModuleProgress('customer_segment'),
      company_sdg: getters.getModuleProgress('company_sdg'),
      market_goals: getters.getModuleProgress('market_goals'),
      company_skill: companySkill ? companySkill.progress : 'NOT_DONE',
      team: getters.getModuleProgress('team') || 'NOT_DONE',
      elevator_pitch: getters.getModuleProgress('elevator_pitch') || 'NOT_DONE',
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  marketPlanLevel: (state, getters) => {
    const obj = {
      market_goals: getters.getModuleProgress('market_goals'),
      customer_segment: getters.getModuleProgress('customer_segment'),
      point_of_differentiation: getters.getModuleProgress(
        'point_of_differentiation'
      ),
      go_to_market_strategy: getters.getModuleProgress('go_to_market_strategy'),
      channels: getters.getModuleProgress('channels'),
      costs: getters.getModuleProgress('costs'),
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  getFoundationLevel: (state, getters) => {
    const companySkill = getters.growthModules.find(
      (module) => module.__typename === 'company_skill'
    );
    const companySdg = getters.growthModules.find(
      (module) => module.__typename === 'company_sdg'
    );
    const obj = {
      vision: getters.getModuleProgress('vision'),
      belief: getters.getModuleProgress('belief'),
      values: getters.getModuleProgress('values'),
      culture: getters.getModuleProgress('culture'),
      market_goals: getters.getModuleProgress('market_goals'),
      traction: getters.getModuleProgress('traction'),
      company_skill: companySkill ? companySkill.progress : 'NOT_DONE',
      company_sdg: companySdg ? companySdg.progress : 'NOT_DONE',
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  getBusinessModelLevel: (state, getters) => {
    const obj = {
      overall_problem: getters.getModuleProgress('overall_problem'),
      overall_solution: getters.getModuleProgress('overall_solution'),
      overall_value_proposition: getters.getModuleProgress(
        'overall_value_proposition'
      ),
      customer_segment: getters.getModuleProgress('customer_segment'),
      channels: getters.getModuleProgress('channels'),
      key_resources: getters.getModuleProgress('key_resources'),
      key_activities: getters.getModuleProgress('key_activities'),
      key_partners: getters.getModuleProgress('key_partners'),
      revenue: getters.getModuleProgress('revenue'),
      costs: getters.getModuleProgress('costs'),
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  getCompetitionLevel: (state, getters) => {
    const obj = {
      point_of_differentiation: getters.getModuleProgress(
        'point_of_differentiation'
      ),
      competition: getters.getModuleProgress('competition'),
      comparison: getters.getModuleProgress('comparison'),
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  getMarketPotentialLevel: (state, getters) => {
    const obj = {
      go_to_market_strategy: getters.getModuleProgress('go_to_market_strategy'),
      tam: getters.getModuleProgress('tam'),
      sam: getters.getModuleProgress('sam'),
      som: getters.getModuleProgress('som'),
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  getRiskAssessmentLevel: (state, getters) => {
    const obj = {
      swot: getters.getModuleProgress('swot'),
      pestle: getters.getModuleProgress('pestle'),
      risk_ananlysis: getters.getModuleProgress('risk_ananlysis'),
    };
    obj['totalPercentage'] = getters.calculateTotal(obj);
    return obj;
  },
  growthFrameworkCompletion: (state, getters) => {
    return {
      foundation: getters.getFoundationLevel,
      business_model: getters.getBusinessModelLevel,
      competition: getters.getCompetitionLevel,
      market_potential: getters.getMarketPotentialLevel,
      risk_assessment: getters.getRiskAssessmentLevel,
    };
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
