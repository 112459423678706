<template>
  <DatePicker
    @input="onInput"
    v-bind="{ ...$props, ...$attrs }"
    prefix-class="vc"
    :disabled-date="disableInvalidDates"
  >
    <template v-slot:input>
      <slot name="input" />
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  props: {
    minDate: {
      type: [String, Date],
      default: null
    },
    maxDate: {
      type: [String, Date],
      default: null
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    disableInvalidDates(date) {
      if (this.minDate && date < new Date(this.minDate)) {
        return true;
      }
      if (this.maxDate && date > new Date(this.maxDate)) {
        return true;
      }
      return false;
    }
  }
};
</script>


<style lang="scss">
$namespace: 'vc';
@import 'vue2-datepicker/scss/index.scss';
.vc-datepicker {
  width: 100%;
}
</style>
