const state = () => ({
  tempUser: null,
  user: null,
  token: null,
  pending_invites: [],
});

const mutations = {
  setTempUser(state, user) {
    state.tempUser = user;
  },
  setUser(state, user) {
    state.user = user;
  },
  updateUser(state, data) {
    state.user = { ...state.user, ...data };
  },
  setToken(state, token) {
    state.token = token;
  },
  setPendingInvites(state, invites) {
    state.pending_invites = invites;
  },
};

const actions = {
  setTempUser({ commit }, user) {
    commit('setTempUser', user);
  },
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  setToken({ commit }, token) {
    commit('setToken', token);
  },
  logout({ commit }, data) {
    commit('setUser', data);
    commit('setToken', data);
  },
  setPendingInvites({ commit }, invites) {
    commit('setPendingInvites', invites);
  },
};

const getters = {
  getUser: (state) => state.user,
  getPendingInvites: (state) => state.pending_invites,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
