import { gql } from '@apollo/client/core';

export const businessModelByTypeQuery = gql`
  query businessModelByTypeQuery(
    $type: business_model_type_enum!
    $companyId: Int!
  ) {
    business_model(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      id
      type
      progress
      priority
      createdAt
      updatedAt
      companyId
      assumption
      description
      business_model_assignments {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      business_model_visibilities {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const subscribeToBusinessModelTypeQuery = gql`
  subscription subscribeToBusinessModelByTypeQuery(
    $type: business_model_type_enum!
    $companyId: Int!
  ) {
    business_model(
      where: { type: { _eq: $type }, companyId: { _eq: $companyId } }
    ) {
      id
      type
      progress
      priority
      createdAt
      updatedAt
      companyId
      assumption
      description
      business_model_assignments {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      business_model_visibilities {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const getCustomersQuery = gql`
  query getCustomersQuery($companyId: Int!) {
    customer(
      where: { business_model: { companyId: { _eq: $companyId } } }
      order_by: { createdAt: asc }
    ) {
      businessModelId
      category
      createdAt
      customer_details(order_by: { createdAt: asc }) {
        createdAt
        customerId
        isDeleted
        title
        id
        value
      }
      description
      id
      name
      priceSensitivity
      problem
      solution
      type
      updatedAt
      valueProposition
      willingnessToPay
    }
  }
`;

export const getChannelsQuery = gql`
  query getChannelsQuery($businessModelId: Int!) {
    channel(
      order_by: { createdAt: asc }
      where: { _and: { businessModelId: { _eq: $businessModelId } } }
    ) {
      id
      type
      name
      topic
      topics {
        topic
        channelId
      }
      customers {
        customerId
        channelId
        customer {
          id
          category
          name
          type
        }
      }
      employeeResponsible
      updatedAt
      createdAt
      customerId
      efficiency
      description
      milestoneId
      businessModelId
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getAllChannelsQuery = gql`
  query getAllChannelsQuery($businessModelId: Int!, $topic: String) {
    channel(
      order_by: { createdAt: asc }
      where: {
        _and: {
          businessModelId: { _eq: $businessModelId }
          topic: { _eq: $topic }
        }
      }
    ) {
      id
      type
      name
      topic
      updatedAt
      createdAt
      customerId
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getKeyResourcesQuery = gql`
  query getKeyResourcesQuery($businessModelId: Int!) {
    key_resource(
      where: { businessModelId: { _eq: $businessModelId } }
      order_by: { createdAt: asc }
    ) {
      id
      name
      type
      createdAt
      updatedAt
      essence
      description
      actionPlan
      milestoneId
      businessModelId
      employeeResponsible
      resources_activities {
        key_resource_id
        key_activity_id
        key_activity {
          name
          id
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getKeyActivitiesQuery = gql`
  query getKeyActivitiesQuery($businessModelId: Int!) {
    key_activity(
      where: { businessModelId: { _eq: $businessModelId } }
      order_by: { createdAt: asc }
    ) {
      id
      name
      type
      createdAt
      updatedAt
      efficiency
      description
      actionPlan
      milestoneId
      businessModelId
      employeeResponsible
      channels {
        channel_id
        key_activity_id
        channel {
          id
          name
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getKeyPartnersQuery = gql`
  query getKeyPartnersQuery($businessModelId: Int!) {
    key_partner(
      where: { businessModelId: { _eq: $businessModelId } }
      order_by: { createdAt: asc }
    ) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      partner_resources {
        key_partner_id
        key_resource_id
        key_resource {
          id
          name
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getRevenueQuery = gql`
  query getRevenueQuery($businessModelId: Int!) {
    revenue(
      where: { businessModelId: { _eq: $businessModelId } }
      order_by: { createdAt: asc }
    ) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      frequency
      description
      milestoneId
      actionPlan
      businessModelId
      employeeResponsible
      revenue_customers {
        id
        customerId
        customer {
          name
          id
          category
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const getCostsQuery = gql`
  query getCostsQuery($businessModelId: Int!) {
    cost(
      where: { businessModelId: { _eq: $businessModelId } }
      order_by: { createdAt: asc }
    ) {
      id
      type
      value
      currency
      updatedAt
      createdAt
      valueArray
      description
      milestoneId
      actionPlan
      businessModelId
      employeeResponsible
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;
